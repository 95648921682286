<template>
 <div id="news" class="news" v-title data-title="新闻详情">
  <!-- 页面导航 -->
  <menu-components type="news"/>

  <!-- 页面主体 -->
  <main class="main">
   <!--banner-->
   <section class="home-banner">
    <el-carousel :autoplay="false" ref="carousel" :interval="5000" arrow="always" height="6.02rem">
     <el-carousel-item v-for="(item, index) in carouselList" :key="index">
      <div class="home-banner-img" :style="{backgroundImage: 'url('+ item.img + ')'}">
       <div class="container">
       <h2>{{item.txt}}</h2>
       <p style="margin-top:.2rem;margin-bottom:.5rem">{{item.txt4}}</p>
       </div>
      </div>
     </el-carousel-item>
    </el-carousel>
   </section>
   <section>
    <div class="container" >
      <div class="news-details-1">
       <h2>{{newsDetails.title}}</h2>
       <p class="cph-font">{{newsDetails.createTime}}</p>
       <div class="news-details-1-img">
        <img :src="newsDetails.bgUrl" alt="">
       </div>
       <div v-html="newsDetails.content"></div>
      </div>

    </div>
   </section>

  </main>

  <!-- 页面底部 -->
  <footer-components style="margin-top:1rem"/>
 </div>
</template>

<script>
import MenuComponents from "@/components/Menu/index.vue";
import FooterComponents from "@/components/Footer/index.vue";
import {getNewsDataDetails} from "@/api";
export default {
  name: 'NewsDetails',
  components: {
   MenuComponents,
   FooterComponents
  },
  mixins: [],
  data () {
    return {
     id: this.$route.query.id,
     // 轮播列表
     carouselList: [
        { img: require('@/assets/images/16.jpg'), txt: '新闻资讯', txt4: 'Media information', jumpUrl: ''},
     ],
     // 当前轮播下标
     initialIndex: 0,
     newsDetails: {}
    };
  },
  watch: {
  },
  mounted () {},
  created () {
   this.getNewsDataDetails()
  },
  methods: {
   /* 获取详情 */
   getNewsDataDetails () {
    getNewsDataDetails(this.id).then(res => {
     if (res.data.code == 0) {
      this.newsDetails = res.data.data;
     } else {
      this.$message.error(res.msg);
     }
    })
   }
  }
};
</script>
<style scoped  lang="scss">

</style>
<style>
</style>
